<template>
  <div class="mb-3">
    <div>
      <Breadcrumb>
        <template slot="restPage">
          <el-breadcrumb-item>
            {{ myTitle }}
          </el-breadcrumb-item>
        </template>
      </Breadcrumb>
      <Heading heading="h4" :content="myTitle"></Heading>
      <small> View {{ myTitle }} </small>
      <hr />
      <Pro :point="info.coins" />
      <UserTransactions :companyId="companyId" />
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant } from "@ivy-way/material";
import Breadcrumb from "@/components/Breadcrumb";
import Pro from "@/views/company/components/Pro.vue";
import Company from "@/views/company/apis/company.js";
import UserTransactions from "@/components/balance/UserTransactions.vue";

export default {
  metaInfo() {
    return {
      title: "Company Info - " + this.CompanyName
    };
  },

  components: { Breadcrumb, Pro, UserTransactions },

  mixins: [],

  props: [],
  data() {
    return {
      info: null,
      companies: null
    };
  },
  computed: {
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    myTitle() {
      return "Licenses";
    },
    companyId() {
      return this.companies ? this.companies[0].id : null;
    }
  },
  watch: {
    companyId() {
      if (this.companyId) {
        this.getInfo();
      }
    }
  },

  mounted() {
    this.getCompanies();
  },

  methods: {
    async getCompanies() {
      const res = await Company.getCompanies({
        page: 1
      });
      this.companies = res.data;
    },
    async getInfo() {
      const res = await Company.getCompanyDetail(this.companyId);
      this.info = res;
    }
  }
};
</script>

<style scoped></style>
